.member-graph-tile{
    align-items: center;
    width:24%;
    height:130px;
    /* border: 2px solid rgb(195, 195, 195); */
    box-shadow: 5px 5px 22px -8px #607d8b;
    border-radius: 10px;
    margin: 0% 1%;
    margin-top: 1%;
    position: relative;
}
.tile-title{
    margin-left: 8%;
    margin-top: 7%;
    text-align: start;
    font-size: 16px;
    color: white;
    font-weight: 600;
}
.tile-count{
    margin-left:  10%;
    margin-top: 5%;
    text-align: start;
    font-size: 30px;
    color: white;
}
.card-img-absolute{
    position: absolute;
    bottom: -15px;
    right: 0;
    height: 150%;
    overflow: hidden;
}
.tile-icon{
    position: absolute;
    float: right;
    width: 55px;
    height: auto;
    right: 2%;
    bottom: 10px;
}