.container{
    padding: 0;
    margin: 0;
    /* height: 300px; */
    overflow: scroll;
}
p{
    line-height: 1.2;
    padding-left: 5px;
}
.sub-container{
    background-color: rgb(240, 240, 247);
    width: 90%;
    border-radius: 10;
    border-width: 10;
    overflow: hidden;
    border-radius: 16px;
  border: 2px solid #fff;
}
.seperator{
    height: 7px;
    width: 100%;
}
.prescriptionAndAdvice{
    font-size: 14px;
    font-family: 'AvenirNext-DemiBold';
    margin-bottom: 3px;

}
.dateContainer{
    text-align: right;
    padding:5px 10px 0px 10px;
}
.text{
    padding:0px 10px 5px 10px;
    word-wrap: break-word;
}