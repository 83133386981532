.member-graph-header {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 149px;
  align-items: center;
  background-color: rgb(231, 238, 245);
}
