.header {
  padding: 7px 0px;
  background: white;
  z-index: 20;
  display: flex;
  flex-direction: row;
  overflow: auto;
  border-bottom: 1px solid rgb(195, 195, 195);
  border-top: 1px solid rgb(195, 195, 195);
}

.content {
  padding: 16px;
  height: 50px;
  width: 100%;
  table-layout: fixed;
  overflow: auto;
}

.sticky {
  position: fixed;
  top: 0;
  width: 89%;
  transform: translate(-0%, 510%);
  overflow: hidden;
}
.lab-category {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-family: 'AvenirNext-Regular';
  font-weight: bold;
  font-size: 17px;
  height: 40px;
  margin-bottom: 5px;
  padding-top: 25px;
}

.lab-sub-category{
  display: flex;
  align-items: center;
  color: black;
  font-family: 'AvenirNext-Regular';
  font-weight: bold;
  font-size: 14px;
  height: auto;
  text-decoration: underline;
  margin-top: 9px;
  margin-left: 10px;
}
