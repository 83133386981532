.graph-parent-container {
  z-index: 0;
}
.graph-parent {
  display: flex;
  /* height:400px; */
}
.tree-map {
  /* border: 2px solid rgb(195, 195, 195); */
  box-shadow: 0px 1px 22px -8px #607d8b;
  display: flex;
  flex-direction: column;
  width: 98%;
  height: auto;
  margin: 1%;
  margin-top: 0;
  margin-bottom: 2%;
  padding: 10px;
  padding-top: 0;
  min-height: 400px;
}
.tree-map-body {
  cursor: pointer;
}

.chart-header {
  padding-left: 8px;
  margin-bottom: -20px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: 'AvenirNext-DemiBold';
  color: #2c2b2bb3;
  z-index: 1;
  flex-direction: row;
}
.tree-map-header-breadcrumb {
  cursor: pointer;
  margin-right: 8px;
}
.bar-graph-header {
  margin-top: 14px;
  margin-bottom: 5px;
  justify-content: space-between;
}
.bar-graph-sub-header {
  float: left;
  display: flex;
  height: 20px;
  width: 72%;
  margin-right: 20px;
}
.bar-graph-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.filter-drop-down {
  display: flex;
  flex-direction: row;
  float: right;
  overflow: hidden;
}
.flagging-family-Chart-parent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 1%;
  padding-right: 0.2%;
}
.flagging-bar-chart {
  width: 64%;
  margin: 0%;
}
.family-bar-chart {
  width: 36%;
  margin: 0%;
}
.flagging-family-box {
  margin: 0;
}

.flagging-header {
  width: 50%;
}
.downloadPdfButton{
  align-self: center;
  margin-right: 0;
  margin-left: 10px;
  border-style: none;
  background-color: transparent;
}
.tree-map-header-parent{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: -10px;
}
.mainBarChart{
  width: 100%;
}
.circularPercentageChart{
  width: 20% ;
}
.familyPercentageChart{
  margin-top: -60px;
  margin-bottom: -10px;
}